<template>
  <b-form-group
    label="Marka"
    label-for="id_brands"
  >
    <v-select
      v-model="dataItem.id_brands"
      :options="brands"
      label="title"
      :reduce="item => item.id"
      placeholder="Marka"
      @input="getProductGroups"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['productGroups/dataItem']
    },
    brands() {
      return this.$store.getters['brands/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getProductGroups() {
      this.dataItem.id_product_groups = null
      if (this.dataItem.id_brands) {
        this.$store.dispatch('productGroups/getDataList', {
          select: [
            'product_groups.id AS id',
            'product_groups.title AS title',
          ],
          where: {
            'product_groups.id_product_groups': null,
            'product_groups.id_brands': this.dataItem.id_brands,
          },
        })
      } else {
        this.$store.commit('productGroups/SET_DATA_LIST', [])
      }
    },
    getDataList() {
      this.$store.dispatch('brands/getDataList', {
        select: [
          'brands.id AS id',
          'brands.title AS title',
        ],
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
