<template>
  <b-form-group
    label="Üst Ürün Grubu"
    label-for="title"
  >
    <v-select
      v-model="dataItem.id_product_groups"
      :options="productGroups"
      label="title"
      :reduce="productGroup => productGroup.id"
      placeholder="Ana Grup"
      :disabled="!dataItem.id_brands"
    />
  </b-form-group>
</template>

<script>
import { localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'ProductGroups',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['productGroups/dataItem']
    },
    productGroups() {
      return this.$store.getters['productGroups/dataList']
    },
  },
  created() {
    this.getDataList()
    localize('tr')
  },
  methods: {
    getDataList() {
      if (this.dataItem.id_brands) {
        this.$store.dispatch('productGroups/getDataList', {
          select: [
            'product_groups.id AS id',
            'product_groups.title AS title',
          ],
          where: {
            'product_groups.id_product_groups': null,
            'product_groups.id_brands': this.dataItem.id_brands,
          },
        })
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
