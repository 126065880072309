<template>
  <b-card>
    <brands />
    <product-groups />
    <title-name />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Brands from '@/views/Admin/Product_groups/elements/Brands.vue'
import TitleName from '@/views/Admin/Product_groups/elements/Title.vue'
import ProductGroups from '@/views/Admin/Product_groups/elements/ProductGroups.vue'

export default {
  name: 'ProductGroupForm',
  components: {
    BCard,
    Brands,
    TitleName,
    ProductGroups,
  },
}
</script>
